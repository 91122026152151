<template>
    <div class="quick-order-layout withTopBar">
        <van-nav-bar title="快捷下单" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
        <div class="quick-form-box">
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">商品名称</div>
            <div class="flex_one">
              <van-field 
                class="wb100"
                readonly
                v-model="curProduct.title"
                placeholder="请输入商品名称"
              />
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">单价(元)</div>
            <div class="flex_one">
              <van-field 
                class="wb100"
                readonly
                v-model="curProduct.selfprice"
                placeholder="单价(元)"
              />
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">每日份数</div>
            <div class="flex_one pdl15">
              <van-stepper v-model="buyNum" theme="round" button-size="16" />
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">配送模式</div>
            <div class="flex_one flex_box align_center space_between" @click="showPopup('delivery')">
              <van-field 
                v-if="!curDelivery.id"
                class="flex_one"
                disabled
                placeholder="请选择配送模式"
              />
              <div class="pdt10 pdb10 pdl15" v-else>{{curDelivery.name}}</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">开始日期</div>
            <div class="flex_one flex_box align_center space_between" @click="showPopup('start')">
              <van-field 
                v-if="startDate==''"
                class="flex_one"
                disabled
                placeholder="请选择开始时间"
              />
              <div class="pdt10 pdb10 pdl15" v-else>{{startDateText}}</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">结束日期</div>
            <div class="flex_one flex_box align_center space_between" @click="showPopup('end')">
              <van-field 
                v-if="endDate==''"
                class="flex_one"
                disabled
                placeholder="请选择开始时间"
              />
              <div class="pdt10 pdb10 pdl15" v-else>{{endDateText}}</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10" v-show="deliveryDays.length >0">
            <div class="label wd60 color_gray_two">天数</div>
            <div class="flex_one pdl15">{{deliveryDays.length >0 ? deliveryDays.length+'天' : ''}}</div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">配送地址</div>
            <div class="flex_one flex_box align_center space_between" @click="selectAddress">
              <van-field 
                v-if="!curAddress.id"
                class="flex_one"
                disabled
                placeholder="请选择配送地址"
              />
              <div class="pdt10 pdb10 pdl15" v-else>{{curAddress.address_str}}</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">奶箱押金</div>
            <div class="flex_one pdl15">{{curAddress.deposit ? curAddress.deposit : 0.00}}元</div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">合计金额</div>
            <div class="flex_one pdl15">{{countMoney*1+ (curAddress.deposit ? curAddress.deposit*1 : 0)}}元</div>
          </div>
          <div class="sub_btn text_center" @click="goOrder">下单并支付</div>
        </div>

        <van-popup v-model="popupFlag" 
                   position="bottom" close-on-popstate safe-area-inset-bottom>
          <van-picker v-show="popupType=='delivery'" show-toolbar title="配送方式" :columns="deliveryData" :default-index="curDeliveryIndex" value-key="name" @confirm="deliveryConfirm" @cancel="onCancel" />
          <van-datetime-picker
            v-show="popupType=='start'"
            :value="startDate"
            type="date"
            title="开始日期"
            :min-date="minDate"
            @cancel="onCancel"
            @confirm="StartConfirm" 
          />
          <van-datetime-picker
            v-show="popupType=='end'"
            :closeable="popupType=='goOrder'"
            :value="endDate"
            type="date"
            title="结束日期"
            :min-date="endMinDate"
            @cancel="onCancel"
            @confirm="endConfirm" 
          />
          <div class="comfirm-box" v-show="popupType=='goOrder'">
            <div class="text_center font22 mb10">订单支付</div>
            <div class="text_center font20 mb10 color_gray_two">订单金额：<span class="color_red">{{countMoney*1+curAddress.deposit*1}}元</span></div>
            <!--<div class="pay-item flex_box align_center space_between" @click="pay_way='2'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-zhifubao font24 color_zfb mr15"></div>
                <span class="font16">支付宝付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='2'" />
            </div>-->
            <div class="pay-item flex_box align_center space_between" @click="pay_way='1'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-weixin font24 color_wx mr15"></div>
                <span class="font16">微信付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='1'" />
            </div>
            <div class="pay-item flex_box align_center space_between" @click="pay_way='3'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-daohang2 font24 color_yellow mr15"></div>
                <span class="font16">线下付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='3'" />
            </div>
            <div class="pay_btn mt15" @click="goCreateOreder">支付</div>
          </div>
        </van-popup>
        <van-popup v-model="showCode" :close-on-click-overlay="false" closeable @click-close-icon="handleClosed">
          <div class="qrcode-box">
            <div class="text_center mb10 font16">请扫码支付</div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="flex_box align_center mt10">
              <span class="mr10 font15">支付剩余时间：</span>
              <div class="">
                <van-count-down :time="time" @finish="finish">
                  <template #default="timeData">
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {dateFormat } from "@/utils/common"
import QRCode from "qrcodejs2";
export default {
    name:"quick-order",
    data(){
        return{
          user:{},
          curProduct:{
            id:"",
            title:"",
            selfprice:""
          },
          buyNum:1,
          deliveryData:[],
          curDelivery:{},//当前配送模式
          curDeliveryIndex:0,
          cycle:{},
          minDate:new Date(),
          startDate:"",
          endDate:"",
          endMinDate:new Date(),
          curAddress:{},
          countMoney:0.00,
          popupFlag:false,
          popupType:"",
          deliveryDays:[],
          pay_way:1,//付款方式 1微信，2支付宝，3线下
          showCode:false,
          payUrl:"",//支付链接weixin://wxpay/bizpayurl?pr=vZjjA5kzz
          time: 5*60*1000,
          timeData:{
            minutes:5,
            seconds:0
          },
          timer:null
        }
    },
    created(){
      let user = localStorage.getItem("cxyUserinfo")
      user ? this.user = JSON.parse(user) : ''
      this.curProduct = JSON.parse(JSON.stringify(this.product))
      console.log(this.curProduct,this.quickOrder)
      if(this.quickOrder.buyNum){
        console.log(this.quickOrder)
        this.buyNum = this.quickOrder.buyNum
        this.deliveryData = this.quickOrder.deliveryData
        this.curDelivery = this.quickOrder.curDelivery
        this.curDeliveryIndex = this.quickOrder.curDeliveryIndex
        this.cycle = this.quickOrder.cycle
        this.minDate = this.quickOrder.minDate ? new Date(this.quickOrder.minDate) : ''
        this.startDate = this.quickOrder.startDate ? new Date(this.quickOrder.startDate) : ''
        this.endDate = this.quickOrder.endDate ? new Date(this.quickOrder.endDate) : ''
        this.endMinDate = this.quickOrder.endMinDate ? new Date(this.quickOrder.endMinDate) : ''
        //this.curAddress = this.quickOrder.curAddress
        this.countMoney = this.quickOrder.countMoney
        this.deliveryDays = this.quickOrder.deliveryDays
        this.pay_way = this.quickOrder.pay_way
      }
      if(this.shifUser.id){
        this.curAddress = JSON.parse(JSON.stringify(this.shifUser))
        //this.$store.commit('setShifUser', {})
      }
      this.getStationDelivery()
    },
    methods:{
      goOrder(){//支付并下单
        if(this.formCheck()){
          this.showPopup('goOrder')
        }else{
          this.$toast.fail('正确填写信息');
        }
      },
      goCreateOreder(){
        if(this.pay_way!=''){
          const _this = this 
          _this.$api.common.quickSendOrder({
            pid:_this.curProduct.id,//产品id
            pcount:_this.buyNum,//单次数量
            deliver:_this.curDelivery.id,
            start_time:_this.startDateText,
            end_time:_this.endDateText,
            address_id:_this.curAddress.id,
            pay_way:_this.pay_way
          }).then(res=>{
            if(res.code =="0" && res.data){
              _this.payUrl = res.data.code_url
              _this.popupFlag = false
              _this.showCode = true
              _this.CheckPaySate(res.data.order_id)
              _this.$nextTick(() => {
                  _this.qrcode()
              })
            }
            if(res.code =="100" && res.data){
              _this.$toast.success('下单成功')
              _this.jumpList()
            }
          })
          
        }else{
          this.$toast.fail('选择支付方式');
        }
      },
      getStationDelivery(){//获取分站配送方式
        const _this = this
        _this.$api.common.getStationDelivery({
          station_id:_this.user.station_id,
        }).then(res=>{
          if(res.code == '0' && res.data){
            this.deliveryData = res.data
          }
        })
      },
      getTimeByDeliver(){//根据配送模式获取对应的配送周期
        const _this = this
        _this.$api.common.getTimeByDeliver({
          station_id:_this.user.station_id,
          pid:_this.curProduct.id,
          deliver:_this.curDelivery.id
        }).then(res=>{
          if(res.code == '0' && res.data){
            console.log(res.data)
            let index = res.data.cycle.findIndex(item =>item.id == 2)
            _this.cycle = res.data.cycle[index] ? res.data.cycle[index] : {}
            _this.startDate = new Date(_this.cycle.start_time)
            _this.minDate = new Date(_this.cycle.start_time)
            _this.endMinDate = new Date(_this.addDays(_this.cycle.start_time,1))
            _this.endDate = ''
            _this.countOneOrderAmount()
          }
        })
      },
      countOneOrderAmount(){
        const _this = this
        if(_this.endDateText !=''){
          _this.$api.common.countOneOrderAmount({
            station_id:_this.user.station_id,
            pid:_this.curProduct.id,//产品id
            pcount:_this.buyNum,//单次数量
            deliver:_this.curDelivery.id,
            start_time:_this.startDateText,
            end_time:_this.endDateText
          }).then(res=>{
            if(res.code == '0' && res.data){
              _this.countMoney = res.data.total_money ? res.data.total_money : 0
              _this.deliveryDays = res.data.dataArr ? res.data.dataArr : []
            }
          })
        }
      },
      selectAddress(){
        this.saveNow()
        this.$router.push({
            name: "user-list",
            query:{
              type:'select',
              preName:"quick-order"
            }
        })
      },
      saveNow(){
        let obj = {
          buyNum:this.buyNum,
          deliveryData:this.deliveryData,
          curDelivery:this.curDelivery,//当前配送模式
          curDeliveryIndex:this.curDeliveryIndex,
          cycle:this.cycle,
          minDate:this.minDate,
          startDate:this.startDate,
          endDate:this.endDate,
          endMinDate:this.endMinDate,
          curAddress:this.curAddress,
          countMoney:this.countMoney,
          deliveryDays:this.deliveryDays,
          pay_way:this.pay_way
        }
        console.log(obj)
        this.$store.commit('setquickOrder',obj)
      },
      showPopup(type){
        this.popupFlag =true
        this.popupType = type
      },
      StartConfirm(value){
        const _this = this
        this.startDate = value
        this.endDate = ''
        this.endMinDate = new Date(_this.addDays(dateFormat(value,"yyyy-MM-dd"),1))
        this.onCancel()
      },
      endConfirm(value){
        this.endDate = value
        this.countOneOrderAmount()
        this.onCancel()
      },
      deliveryConfirm(obj,index){
        console.log(obj,index)
        this.curDelivery = obj
        this.curDeliveryIndex = index
        this.getTimeByDeliver()
        this.onCancel()
      },
      onCancel(){
        this.popupFlag =false
        this.popupType = ""
      },
      addDays(date, days,seperator='-') {
        let oDate = new Date(date).valueOf();
        let nDate = oDate + days * 24 * 3600 * 1000;
        nDate = new Date(nDate);
        let y = nDate.getFullYear().toString().padStart(2, 0);
        let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
        let d = nDate.getDate().toString().padStart(2, 0);
        let result =`${y}${seperator}${m}${seperator}${d}`
        return result.toString()
      },
      formCheck(){
        if(!this.curDelivery.id || this.startDateText=='' || this.endDateText=='' || !this.curAddress.id){
          return false
        }
        return true
      },
      finish(){
        this.$toast.fail('支付超时')
        this.showCode = false
        this.jumpList()
      },
      clearTimer(){
          this.timer ? clearInterval(this.timer) : '' //关闭
      },
      qrcode() {
        const _this = this
        new QRCode("qrcode", {
            width: 300, // 二维码宽度，单位像素
            height: 300, // 二维码高度，单位像素
            text: _this.payUrl// 生成二维码的链接
        })
      },
      CheckPaySate(orderId){
          const _this = this
          this.timer=setInterval(()=>{
              _this.$api.common.getPayState({
                  order_id:orderId
              }).then(res=>{
                  if(res.code =="0" && res.data && res.data.pay_status == 1){
                      _this.clearTimer()
                      _this.showCode = false
                      _this.$toast.success('支付成功')
                      _this.jumpList()
                  }
              })
          },5000)
      },
      handleClosed(){
        this.$toast.fail('支付取消')
        this.jumpList()
      },
      jumpList(){
        const _this = this
        setTimeout(()=>{
          _this.$router.push({
            name: "order-list"
          })
        },1500)
      },
      onClickLeft(){
        this.$router.push({
            name: "product-list"
        })
      }
    },
    computed:{
      ...mapState(["product","shifUser","quickOrder"]),
      startDateText(){
        return this.startDate ? dateFormat(this.startDate,"yyyy-MM-dd") : ''
      },
      endDateText(){
        return this.endDate ? dateFormat(this.endDate,"yyyy-MM-dd") : ''
      }
    },
    beforeDestroy(){
      console.log(1111)
      this.$toast.clear()
      this.clearTimer()
    }
}
</script>

<style lang="scss">
@import "./quick-order.scss"
</style>


